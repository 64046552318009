import React, { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { isEmpty } from "lodash";
import { BrowserView, MobileOnlyView, MobileView } from "react-device-detect";
import NavDropdown from "react-bootstrap/NavDropdown";

import { AuthContext } from "../../contexts/AuthContext";

function Header1() {
  const { isAuthenticated, currentUser } = useContext(AuthContext);

  return (
    <>
      <div className="header dashboard">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="navigation">
                <Navbar bg="light" expand="lg">
                  <BrowserView>
                    <Link className="navbar-brand" to={"/"}>
                      <img src={require("./../../images/logo.png")} alt="" />
                    </Link>
                  </BrowserView>
                  <MobileOnlyView>
                    <Link className="navbar-brand" to={"/"}>
                      <img src={require("./../../images/logo-spin.png")} width={`50px`} alt="" />
                    </Link>
                  </MobileOnlyView>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="ms-auto">
                      <Nav.Item>
                        <NavLink to="/">Home</NavLink>
                      </Nav.Item>
                      <NavDropdown title="Trade">
                        <NavLink to="/exchange/polygon/cax_dai" activeClassName="active" exact="true">
                          Spot Market
                        </NavLink>
                        <NavDropdown.Divider />
                        <NavLink to="/not-found" exact="true">
                          CAX swap
                        </NavLink>
                      </NavDropdown>
                      {isAuthenticated && (
                        <Nav.Item>
                          <NavLink to="/on-ramp">Buy Crypto </NavLink>
                        </Nav.Item>
                      )}
                      <Nav.Item>
                        <NavLink to="/farm">Earn/Stake </NavLink>
                      </Nav.Item>
                      <Nav.Item>
                        <NavLink to="/token-info">Token Info Base</NavLink>
                      </Nav.Item>
                      <Nav.Item>
                        <NavLink to="/blogs">Blog</NavLink>
                      </Nav.Item>
                      <MobileView>
                        {!isAuthenticated && isEmpty(currentUser) && (
                          <Link className="btn btn-primary ms-3" to={"/signin"}>
                            Sign in
                          </Link>
                        )}
                      </MobileView>
                    </Nav>
                  </Navbar.Collapse>
                  <BrowserView>
                    <div className="signin-btn">
                      {!isAuthenticated && isEmpty(currentUser) && (
                        <Link className="btn btn-primary ms-3" to={"/signin"}>
                          Sign in
                        </Link>
                      )}
                    </div>
                  </BrowserView>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header1;
