import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Footer1 from "../layout/footer1";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Bottom from "../element/bottom";

export const TermsOfUse = () => {
  return (
    <>
      <Header2 />
      <div className="content-body" style={{ marginTop: "45px" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header fw-bold">
                  <h3>Terms of use</h3>
                </div>
                <div className="card-body">
                  <div className="legal-doc">
                    <span>
                      <p>
                        This agreement will govern the use of the online and mobile digital platform called Caribbean
                        and Latin America Decentralized Exchange (Caladex) which is developed, provided, and owned by
                        Caladex SRO, a limited liability company organized under the laws of the Czech Republic. When
                        this Agreement mentions “Caladex,” “we,” “us,” or “our,” it refers to the relevant Caladex SRO
                        entity which is providing the services. The entity providing services will be determined based
                        on your country of residence. Your “country of residence’ is the jurisdiction associated with
                        your account as determined by your official identification document and verified residential
                        address. The user agreement is a contract between Caladex and you as a user (“you, user or
                        your”) to buy, sell or list Digital Assets, hold Digital Assets, and use the Application
                        Programming Interface (API) and other services offered through the www.caladex.org site or
                        official Caladex mobile version. When you register for a Caladex account to get your wallet
                        whitelisted or for the specific purpose to purchase crypto with fiat you agree that you have
                        read, understood completely and have accepted all terms and conditions set forth in this
                        agreement, including our privacy policy, cookie policy and E-sign consent and you agree that you
                        will abide by these agreements and policies.
                      </p>
                      <h4>1. Legal compliance</h4>
                      <hr />
                      <p>
                        Our services are not offered to individuals who are citizens residing, or companies who are
                        incorporated and have registered offices in the United States of America. Our services are
                        subject to all applicable export control regulations and you may not use the services if you are
                        a resident, official representative or national of Iran, Cuba, North Korea, Sudan, Seychelles,
                        Syria, or any other country on which the United States of America, the United Kingdom or the
                        European Union have placed an embargo of goods or imposed other similar sanctions (e.g. member
                        of any sanctions list or equivalent issued by the United States of America, the United Kingdom
                        or the European Union). In addition you may not use the services to trade in margin and or
                        futures if you are a resident, official representative or national of Antigua and Barbuda,
                        Algeria, Bangladesh, Bolivia, Belarus, Burundi, Burma, Cote D'Ivoire (Ivory Coast), Crimea and
                        Sevastopol, Cuba, Democratic Republic of Congo, Ecuador, Iran, Iraq, Libya, Mali, Morocco,
                        Magnitsky, Liberia, North Korea, Nepal, Seychelles, Somalia, Sudan, Syria, United Kingdom,
                        Venezuela, and Zimbabwe. We reserve the right to block any additional jurisdiction from time to
                        time and at any time to our sole discretion. When we use the term Digital Assets in this user
                        agreement, we refer to Bitcoin, Ethereum, Ripple or any other digital assets, crypto currencies
                        or tokens that are or will be available through Caladex. You agree that we may modify or change
                        the terms and conditions of this agreement and in doing so we will provide you with notice of
                        the revised agreement. If you do not agree with the revised agreement, you shall notify us
                        promptly and immediately discontinue your use of Caladex services. Continued use of Caladex
                        services shall constitute acceptance of the terms and conditions set forth in the revised
                        agreement.
                      </p>
                      <h4>2. Laws and regulation</h4>
                      <hr />
                      <p>
                        The Caladex platform is subject to the laws, regulations, and rules of any relevant governmental
                        or regulatory authority, which includes all relevant tax, anti-money laundering (AML) and
                        counter terrorist financing (CTF) provisions. If your country of residence is within the
                        European Economic Community (EC) your representative for the purpose of the General Data
                        Protection Regulation (EU) 2016/679 (GDPR) is Caladex SRO. If your Country of Residence is
                        outside of the EC, provisions related to the GDPR shall not apply to your use of the services.
                        By opening an account and using Caladex services regarding crypto purchase using fiat and
                        whitelisting your wallet, you agree and fully understand that you shall act in accordance with
                        and be legally bound by this agreement and all relevant laws and regulations. In addition, you
                        should comply with any reasonable requests by us for information, documents and agreements
                        related to any of the services we offer through our platform. Failure to do so may result in the
                        closure of your account and ability to use the services provided by Caladex.
                      </p>
                      <h4>3. Permissions</h4>
                      <hr />
                      <p>
                        To access the Caladex platform you must be at least 18 years old and have not previously been
                        suspended or removed from the Caladex platform. You must also warrant and represent that you are
                        not a restricted person nor are you a citizen of the United States of America or restricted
                        territory as defined in section 1. You represent and warrant that you will not be using Caladex
                        for any illegal activity, including without limitation illegal gambling, money laundering,
                        fraud, blackmail, extortion, ransoming data, the financing of terrorism, other violent
                        activities, or any prohibited market practices. Caladex reserves the right to make the services,
                        in whole or in part, available in every market, either in its sole discretion or due to legal or
                        regulatory requirements, depending on the user’s location.
                      </p>
                      <h4>4. Caladex services</h4>
                      <hr />
                      <p>
                        a) The Caladex platform will permit you to place buy and sell orders of and facilitate the
                        settlement of transactions relating to the purchase and sale of, Digital Assets, including
                        Bitcoin, Ethereum, or other cryptos or digital assets authorized by us to be traded on our
                        platform.
                      </p>
                      <p>
                        b) Our platform will enable you to obtain trade data, price, and research information regarding
                        Digital Assets.
                      </p>
                      <p>
                        c) In addition, our platform allows you to submit a token for listing according to the basic
                        requirements of the Ethereum ECR-20 Standard protocol, Polygon protocol or Binance Smart Chain
                        protocol. Submitted token listings are reviewed by us and either approved or rejected in our
                        sole discretion.
                      </p>
                      <h4>5. Account registration and identity verification</h4>
                      <hr />
                      <p>
                        Current, complete, and accurate information is required on the registration page in case you
                        wish to purchase crypto with fiat or decide to whitelist your wallet. You agree to provide us
                        with the correct information on a continued basis. You are the only person authorized to use
                        your account and you are not allowed to share your account credentials with any other person.
                        You permit us to keep a record of this information and allow us to make direct or third-party
                        inquiries to verify your identity or to protect you and other users, and/or us against fraud or
                        other financial crime, and take the necessary action based on the results of such inquiries. You
                        fully understand and agree that when we carry out these inquiries your personal information may
                        be disclosed to credit reference and fraud prevention or financial crime agencies. You warrant
                        that all information provided to us pursuant to this Agreement or otherwise is true, accurate
                        and not misleading in any respect. If any such information changes, it is your obligation to
                        update such information as soon as possible. Failure to do so may result in the suspension of
                        your ability to use the services or the closure of your account.
                      </p>
                      <h4>6. Third party transfers</h4>
                      <hr />
                      <p>
                        Caladex users may purchase Digital Assets directly using fiat currency through Moonpay, and
                        other payment gateway providers we might integrate in the future so you can send purchased
                        Digital Assets to the designated address of your choosing. Alternatively, if you already own
                        Digital Assets, you may deposit Digital Assets you already own on the Caladex balance. It is
                        your responsibility to ensure you deposit the Digital Assets to the Caladex balance for that
                        specific Digital Asset using the proper network, otherwise your funds may never be recovered.
                      </p>
                      <p>
                        In addition, Caladex does not guarantee or issue any warranties regarding the length of time it
                        may be required to process a transfer of your Digital Asset from a third-party wallet or other
                        source to our balance or ‘vice versa.’ The possibility remains that if you transfer Digital
                        Assets from your account to a third party that the new location may reject your transfer due to
                        technical difficulties or other issues affecting our platform. You fully agree that you shall
                        not hold Caladex liable for any damages arising from a rejected transfer.
                      </p>
                      <h4>7. Communications and notifications</h4>
                      <hr />
                      <p>
                        All electronic communications and notifications are established in our E-Sign Consent Policy,
                        which is part of this User Agreement. The primary language for this will be English. Any
                        translations of the English version into Spanish or other languages are only for your
                        convenience.
                      </p>
                      <h4>8. Platform access and general rules</h4>
                      <hr />
                      <p>
                        a) The market data provided to you is for internal purposes only. You agree that you will not
                        publish or circulate this market data to any third party. You agree that neither Caladex SRO LLC
                        nor Caladex is your investment adviser or fiduciary and that the market data we provide through
                        our platform does in no way constitute our recommendation or solicitation to engage in any
                        particular transaction. Market data on our platform is prepared and provided by independent
                        information companies. You should always conduct further research either by yourself or an
                        investment consultant. The use of market data including price quotes, news and research,
                        financial reports etc., is at your own risk.
                      </p>
                      <p>
                        b) When you register on our platform you are responsible for maintaining the confidentiality of
                        your account and password. You understand and agree that you will not give your account password
                        to anyone whom you do not intend to authorize to use the account. You are fully responsible for
                        all activities that take place using your password or account and we cannot be held liable for
                        any loss you may incur due to someone else using your password or account with or without your
                        consent or knowledge. You should notify us as soon as possible when there is a breach of
                        security. Caladex will never ask for your password by any means of communication. Email links
                        claiming to be from Caladex requesting you to give your password are not from our platform and
                        should never be opened or clicked on because they are likely to be fraudulent.
                      </p>
                      <p>
                        c) You may fund your balance at Caladex with Digital Assets transferred from third party
                        accounts. We do not charge for deposits, but third parties may charge for transaction and other
                        fees. Digital Assets in this case will be transferred to our main Caladex balance and credited
                        to your wallet. You are allowed to withdraw part or all of the Digital Assets under your wallet
                        address recorded on the Caladex ledger. Your electronic statements, including balance, order
                        history, deposits, withdrawals, and transactions are available for viewing anytime. You agree
                        that sometimes errors may occur that do not influence the actual results of a given transaction.
                        Any transaction listed in the statement or other communication including, but not limited to,
                        receipts sent to your email address on record shall be deemed and treated as authorized and
                        correct, approved, and confirmed by you unless we receive a written notice from you to the
                        contrary within three calendar days from the date the communication was sent.
                      </p>
                      <p>
                        d) You fully understand and agree that you are bound by any orders you place through our
                        platform. You agree that you are fully responsible for the maintenance of your passwords and
                        security codes and any unauthorized access to your wallet. This also includes the passwords and
                        the security codes of your authorized person(s). In case of theft of your passwords, and/or
                        security codes, you agree to report this immediately to us. It is not our duty to verify whether
                        any order has been authorized by you or other authorized person(s) and therefore we cannot be
                        held liable for any theft of passwords and security codes of you or your authorized person(s).
                      </p>
                      <p>
                        e) Caladex is a trading platform that allows you to place buy and sell orders and facilitates
                        the settlement of the purchase or sale between users. The platform simply matches buy and sell
                        orders and we do not act as a principle in or participate in those transactions. Therefore, we
                        are not responsible for any disputes among or between users regarding any transaction. Our
                        software and models automatically pair orders and a notification is sent to the user that the
                        order has been executed. Market order execution is instantaneous, so never place an order if you
                        do not fully intend to complete the transaction. The caladex sro - Terms of Use platform offers
                        you the chance to modify or cancel an order but only before it is matched and executed. However,
                        we do not accept any responsibility if you cannot modify or cancel an order. In case you cannot
                        modify or cancel an order, or in case your multiple attempts in doing so result in a duplicate
                        order, you will be bound by any execution of the original order and duplicate orders. You fully
                        understand that you are responsible for all executions. All orders executed, thus meaning the
                        indicated Digital Assets have been credited into and debited from your account, are
                        irreversible. You agree that due to technical and other restrictions, the price of any Digital
                        Asset displayed on our platform may be delayed and therefore may not reflect the current live
                        market value of such Digital Asset. However, you accept that the value of your account is the
                        right one considering these circumstances. You will not hold Caladex liable for orders that may
                        be traded ahead of yours, bids and offers that may be exhausted at a posted price, market makers
                        that fail to honor their posted prices, automated execution systems that reroute user orders,
                        policies, procedures or system delays or failures that may prevent your order from being
                        executed or that may delay the execution of your order or may cause your order not to be
                        executed at the best price.
                      </p>
                      <p>
                        f) Currently Caladex is not charging any fee for services rendered on our platform. However,
                        reserve the right to change, modify or increase these fees at any time and they will be duly
                        posted on our platform from time to time. If you do not agree you may agree not to use our
                        platform. If you continue to use our platform following any updates on fees it will be
                        considered the acceptance of all such changes or revisions.
                      </p>
                      <p>
                        g) There are reasons that we may at any time and at our sole discretion, limit, suspend,
                        terminate, or issue a warning to you regarding the services that are available to you or in part
                        disable certain functions such as depositing, receiving, sending and/or withdrawing Digital
                        Assets if: We think it is in your best interest, in other words to protect the security of your
                        account. the information you provided us cannot be verified or authenticated. transactions are
                        made which we suspect or consider to be a threat to our platform, a breach of security,
                        unauthorized, fraudulent, suspected of money laundering, suspected of terrorism financing, or
                        other illegal activities. We think or believe that your actions may cause legal liability for
                        you, other users, or the platform. We decide to cease all operations or discontinue our
                        services, or parts thereof. we are directed as such by any governmental authority. We are
                        required to do so by applicable law.
                      </p>
                      <p>
                        We will always make a reasonable effort to inform you of any suspension, limitation, or
                        termination in advance or in case this is not feasible, due to time constraints, we will do so
                        directly after the measures are taken. If your account is no longer subject to an investigation,
                        court order, or subpoena, you will authorize Caladex to return your funds minus any trading fee
                        discounts, rebates, and/or damages to which Caladex is entitled, to any bank account linked to
                        the account or any other third- party Digital Assets address indicated by you. Caladex will not
                        be liable nor any other third party acting on our behalf for any suspension, limitation or
                        termination of your account or access to any of our services offered through our platform in
                        accordance with this agreement.
                      </p>
                      <h4>9. Liability limitations</h4>
                      <hr />
                      <p>
                        You fully understand and agree that to the maximum extent permitted by applicable law that you
                        hereby discharge, acquit, and otherwise release us, our parent company, subsidiaries,
                        affiliates, respective o icers, directors, shareholders, members, partners, attorney’s,
                        coworkers, independent freelancers, telecommunication providers and agents from any and all
                        allegations, counts, charges, debts, causes of action, claims and losses, expenses, costs,
                        demands, legal and accounting fees, relating in any way to the use of our site, the platform,
                        and services we provide. This also includes any misuse of, or inability to use the platform, our
                        site, and services, regardless of whether such damages are direct or indirect, special,
                        incidental, or consequential damages for trading losses, loss of information, business
                        interruption or lost profits, or any claim or demand by any third party.
                      </p>
                      <p>
                        This Agreement shall be governed by, and construed in accordance with, the laws of Aruba without
                        regard to the conflicts of law rules in other jurisdictions. You agree and understand that your
                        obligations and the obligations of each user set forth in this agreement are necessary and
                        reasonable to protect us and the platform. You expressly agree that due to the unique nature of
                        our business, monetary damages would be inadequate to compensate us for any breach by you of the
                        covenants and agreements set forth in this agreement. Accordingly, you agree and understand that
                        any such violation or threatened violation shall cause irreparable injury to us and that, in
                        addition to any other remedies that may be available, in law, in equity or otherwise, we shall
                        be entitled to obtain relief against any threatened breach of this agreement or your
                        continuation of any such breach, without the necessity of proving actual damages.
                      </p>
                      <h4>10. Trading risks</h4>
                      <hr />
                      <p>
                        There are different risks associated with Digital Assets and hereafter we are listing a couple
                        of those risks, but the list should not be considered all-inclusive.
                      </p>
                      <p>
                        a) Caladex provides a trading only service, we do not advise our customers on whether any buy or
                        sell order is a good one or a bad one. Any decision to buy or sell Digital Assets is the user’s
                        decision only and Caladex will not be liable for any loss suffered.
                      </p>
                      <p>
                        b) If we, or any of our representatives provide trading recommendations, market commentary, or
                        any other information, we do so independently to our relationship with you and you will not
                        consider that as investment or financial advice from our part and it should never be considered
                        as such.
                      </p>
                      <p>
                        c) You understand that trading Digital Assets is risky, and that they are very volatile and
                        unpredictable when it comes to price fluctuations. You accept and agree that you will be solely
                        responsible for doing your own research and investigation into the risks of any transaction
                        regarding Digital Assets. You confirm that you have enough knowledge, market understanding,
                        professional advice, and experience to make your own evaluation of any risks associated with
                        trading in any Digital Asset.
                      </p>
                      <p>
                        d) You understand and agree that Blockchain networks can go offline because of bugs, forks,
                        attacks, or any number of reasons. Therefore, our general advice to any customer with limited
                        trading experience and low risk tolerance is pretty simple; do not engage in active trading and
                        if you do so against our advice be sure never to trade more than you can afford to lose.
                      </p>
                      <p>
                        e) Digital Assets can potentially lose their total value because their value is derived from the
                        continued willingness of the market participants to exchange Digital Assets.
                      </p>
                      <p>
                        f) Development and control over software protocols regarding Digital Assets are beyond our
                        control. Most Digital Assets are open-source projects. These protocols might be subject to
                        sudden and unpredicted changes that might have a significant impact on the availability, value,
                        and usability of a particular Digital Asset.
                      </p>
                      <p>
                        g) We might suffer from unexpected surges in activity on our platform, technical difficulties or
                        cyber-attacks that can cause our platform to interrupt our services. You accept the risks
                        involved and you agree not to hold Caladex responsible for any related losses.
                      </p>
                      <p>
                        h) You are responsible to comply with all applicable laws in your territory. Caladex is not
                        responsible for determining which laws may apply to your transactions and this also includes tax
                        laws. You are solely responsible for reporting and paying any taxes arising from your use of our
                        services.
                      </p>
                      <p>i) The Digital Assets in your account are not protected by any insurance.</p>
                      <p>
                        j) Some Digital Assets are built on protocols that support staking. This means that if you hold
                        these Digital Assets in your account that they carry additional risks. Caladex is not
                        responsible for any losses you may suffer related to staking.
                      </p>
                      <h4>11. Copyright issues</h4>
                      <hr />
                      <p>
                        If there is anything on this site that you belief to be an infringement upon a copyright which
                        you own or control, please notify us of such an infringement. Such a notification can be sent
                        to:
                      </p>
                      <p>
                        <h6>Caladex SRO</h6>
                        <h6>Varšavská 715/36, 120 00 Prague – Vinohrady.</h6>
                        <h6>Czech Republic Attn: Chief Executive Officer</h6>
                      </p>
                      <p>
                        Please note that if you knowingly place a false infringement that you will be liable for any
                        costs and damages, including attorneys’ fees incurred by us by such allegations. Any
                        infringement is never done purposely by us and if we find the infringement to be correct, we
                        will immediately remove, replace, or disable access to the material or activity claimed to be
                        infringing.
                      </p>
                      <h4>12. Disputes</h4>
                      <hr />
                      <p>
                        We are a transparent company, and we go with the flow as we say. Be happy is our slogan and life
                        is full of opportunities. Therefore, we ask you politely that if you have a dispute with us to
                        contact our support team to attempt to resolve such a dispute in a subtle way. If for some
                        reason we cannot resolve the dispute through the Caladex support team you agree to follow a
                        formal complaint process as mentioned hereafter.
                      </p>
                      <p>
                        You will send an email to customerservice@caladex.org stating your formal complaint in a clear
                        and precise matter. A customer service agent will review your complaint based on the information
                        you have provided and the information Caladex has in her possession. Within 10 business days of
                        our receipt of your complaint the agent will address your complaint by sending you an email in
                        which the agent will either offer to resolve your complaint in the way you wish it to be
                        resolved, reject your complaint and state the reasons why or o er to resolve your complaint
                        proposing an alternative solution.
                      </p>
                      <p>
                        If we cannot resolve the dispute through this formal request than we agree that any dispute
                        arising out or relating to this agreement shall be resolved through the court of first instance
                        in the Czech Republic.
                      </p>
                      <h4>13. Delisting</h4>
                      <hr />
                      <p>
                        This is not something we like to do, but at any time we may delist a Digital Asset in our sole
                        discretion based on the following.
                      </p>
                      <p>1. Low trade volume on the exchange of our platform</p>
                      <p>2. No developer support for the project or decrease in such support.</p>
                      <p>3. Costly upgrades to keep supporting the project.</p>
                      <p>4. The project is facing legal issues.</p>
                      <p>5. Project is a fraud.</p>
                      <p>6. Project is a security breach for our platform.</p>
                      <p>
                        We will notify our whitelisted customers if we delist and will see to it that you have ample
                        time to withdraw your balance invested in such a Digital Asset. Deadlines will be sent to you
                        electronically (see chapter 7) but there is no guarantee that you can withdraw a delisted
                        Digital Asset if the network is no longer alive. This is an example of a reason beyond our
                        control, and you agree that we cannot be held reliable in such a case.
                      </p>
                      <h4>14. Ownership</h4>
                      <hr />
                      <p>
                        You confirm with this agreement that you own the Digital Assets in your account and are
                        authorized to carry out transactions using such Digital Assets and that any transaction
                        initiated by you are your own responsibility. To be clear, Caladex does not own any Digital
                        Assets held by users on our platform.
                      </p>
                      <h4>15. Applicable laws</h4>
                      <hr />
                      <p>
                        You confirm with this agreement that you own the Digital Assets in your account and are
                        authorized to carry out transactions using such Digital Assets and that any transaction
                        initiated by you is your own responsibility. To be clear, Caladex does not own any Digital
                        Assets held by users on our platform.
                      </p>
                      <h4>16. Content</h4>
                      <hr />
                      <p>
                        At Caladex we strive to provide you with accurate and reliable information but there is always a
                        possibility that such information may not be correct, incomplete or not yet updated. Caladex
                        will always update information as fast as we can but it is your responsibility to verify the
                        information. Caladex site contains links to third party websites and applications. This is done
                        for your convenience. This should never be construed as an endorsement from Caladex and we will
                        not be liable for any losses incurred as a result of actions taken by you on our platform or in
                        any other third party content website or application. You should always investigate third party
                        sources on your own.
                      </p>
                      <h4>17. User rights</h4>
                      <hr />
                      <p>
                        We hereby grant you a non-exclusive and non-transferable license in accordance with the terms of
                        use and conditions set forth in this agreement to access our platform and the services we
                        provide for approved purposes as defined by Caladex. Any other use of the services is strictly
                        prohibited. You agree that you will not, under any circumstances copy, scrape, rent, lease,
                        loan, distribute, sell or create clones based on our services, in whole or in part. If you
                        violate any portion of this agreement we will terminate your access to our platform. Caladex,
                        caladex.org and all logos related to Caladex are registered trademarks of Caladex. You cannot
                        copy them, use them, or imitate them without the consent of Caladex. All the apps, technology
                        and content created on the platform is the exclusive property of Caladex. We own the
                        intellectual property of our products and services. You can use our products and services but we
                        still own the intellectual property associated with these products and services.
                      </p>
                      <h4>18. Contact Information</h4>
                      <hr />
                      <p>
                        Caladex will post notices about our platform and other kinds of services information on our
                        Facebook, Twitter, and Instagram accounts @caladex.org. It would be wise to visit and check
                        these social sites for certain information prior to contacting support. If you need support,
                        click on the support button, and open a ticket. Provide all relevant information and we will get
                        back to you as soon as possible.
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Bottom />
      <Footer1 />
    </>
  );
};
