import React, { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, useHistory, NavLink } from "react-router-dom";
import { isEmpty } from "lodash";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import { Web3Button } from "@web3modal/react";
import NavDropdown from "react-bootstrap/NavDropdown";

import { AuthContext } from "../../contexts/AuthContext";
import { setRefreshToken } from "../../shared/helpers";

const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div className="profile_log">
      <div className="user">
        <span className="thumb">
          <i className="mdi mdi-account"></i>
        </span>
        <span className="arrow">
          <i className="la la-angle-down"></i>
        </span>
      </div>
    </div>
  </div>
));

function Header2({ activePage }) {
  const history = useHistory();

  const { setAuthToken, currentUser, isAuthenticated, setAuthenticated, setCurrentUser, authToken, tokenPriceRefresh } =
    useContext(AuthContext);

  const handleLogout = (e) => {
    e.preventDefault();

    setAuthToken("");
    setRefreshToken("");
    setCurrentUser({});
    setAuthenticated(false);

    history.push("/signin");
    return false;
  };

  return (
    <>
      <div className="header dashboard">
        <div className="container-fluid px-md-5">
          <div className="row">
            <div className="col-xl-12">
              <div className={`navigation px-md-3 justify-content-between`}>
                <Navbar bg="light" expand="lg">
                  <BrowserView>
                    <NavLink className="navbar-brand" to={"/"} exact="true">
                      <img src={require("./../../images/logo.png")} alt="" />
                    </NavLink>
                  </BrowserView>
                  <MobileOnlyView>
                    <NavLink className="navbar-brand" to={"/"} exact="true">
                      <img src={require("./../../images/logo-spin.png")} width={`50px`} alt="" />
                    </NavLink>
                  </MobileOnlyView>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="ms-auto">
                      <Nav.Item>
                        <NavLink to="/" activeClassName="active" exact="true">
                          Home
                        </NavLink>
                      </Nav.Item>
                      <NavDropdown title="Trade">
                        <NavLink to="/exchange/polygon/cax_dai" activeClassName="active" exact="true">
                          Spot Market
                        </NavLink>
                        <NavDropdown.Divider />
                        <NavLink to="/" exact="true">
                          CAX swap
                        </NavLink>
                      </NavDropdown>
                      {isAuthenticated && (
                        <Nav.Item>
                          <NavLink to="/on-ramp" activeClassName="active" exact="true">
                            Buy Crypto
                          </NavLink>
                        </Nav.Item>
                      )}
                      <Nav.Item>
                        <NavLink to="/farm" activeClassName="active" exact="true">
                          Earn/Stake
                        </NavLink>
                      </Nav.Item>
                      <Nav.Item>
                        <NavLink exact="true" to="/token-info" activeClassName="active">
                          Token Info Base
                        </NavLink>
                      </Nav.Item>
                      {isAuthenticated && (
                        <>
                          <Nav.Item>
                            <NavLink to="/account-overview" activeClassName="active" exact="true">
                              Account
                            </NavLink>
                          </Nav.Item>
                        </>
                      )}
                      <Nav.Item>
                        <NavLink to="/blogs" activeClassName="active" exact="true">
                          Blog
                        </NavLink>
                      </Nav.Item>
                      {!isAuthenticated && isEmpty(currentUser) ? (
                        <Nav.Item>
                          <NavLink to="/signin" activeClassName="active" exact="true">
                            Sign in
                          </NavLink>
                        </Nav.Item>
                      ) : (
                        <Nav.Item>
                          <Nav.Link to="#" onClick={handleLogout} exact="true">
                            Sign out
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                    <div className="d-flex flex-column flex-md-row my-2 my-md-0 align-items-center float-md-right">
                      <div className="signin-btn">
                        <Web3Button />
                      </div>
                    </div>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header2;
