import axios from "axios";
import React, { useEffect } from "react";

export const LocationContext = React.createContext({});

export const LocationContextProvider = ({ children }) => {
  const [location, setLocation] = React.useState("");
  const [locationLoading, setLocationLoading] = React.useState(false);

  useEffect(() => {
    const fetchLocationData = async () => {
      setLocationLoading(true);
      try {
        await axios.get("https://geolocation-db.com/json/").then((res) => {
          console.log("country is ", res.data.country_code);
          setLocation(res.data.country_code);
        });
      } catch (e) {
        console.log("fetch-location-data-error:", e);
      } finally {
        setLocationLoading(false);
      }
    };
    fetchLocationData();
  }, []);

  return (
    <LocationContext.Provider
      value={{
        location,
        locationLoading,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
