import React, { useCallback, useRef, useState, useEffect, useContext } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { CircularProgress } from "@mui/material";
import { isEmpty, isUndefined, uniqueId } from "lodash";
import moment from "moment";
import Axios from "axios";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";

import {
  BACKEND_API_URL,
  BACKEND_FILE_URL,
  CHAIN_LIST,
  EXCHANGE_LIMIT,
  ORDER_SIDE,
  ORDER_FILTER,
  ORDER_TYPE,
  SWALLOW_DEGITS,
  TOKEN_DIGITS,
} from "../../shared/constants";
import AreaChart from "../charts/area";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import {
  useNewsList,
  useTokenOrderList,
  useTokenTradeList,
  useTradingViewData,
  useWalletCaladexBalance,
  useWalletTradingPairBalance,
} from "../../state/hooks";
import {
  atomTargetTokenList,
  atomTokenOrderList,
  atomWalletTradingPairBalance,
  atomTokenPairList,
  atomTokenTradeHistory,
  atomTradingViewData,
  atomWalletCaladexBalance,
} from "../../state/atoms";
import { formatDegits, getSymbolsFromTradingPair, idValidationSchema } from "../../shared/helpers";
import { humanReadableAccount } from "../../shared/web3";
import { AuthContext } from "../../contexts/AuthContext";
import useLocalStorage from "../../shared/useLocalStorage";

export const ImageContainer = (props) => {
  const { src: imageSrc, iconClass } = props;
  const [src, setSrc] = useState("");
  const [isLoaded, setLoaded] = useState(false);

  const setData = useCallback(() => {
    setSrc(imageSrc);
    const img = new Image();
    img.src = props.src || "";
    img.onload = () => {
      setLoaded(true);
    };
  }, [src]);

  useEffect(() => {
    setData();
  }, []);

  return !isLoaded ? (
    !src ? (
      <CircularProgress color="warning" style={{ width: "20px", height: "20px" }} />
    ) : (
      <span>
        <i className={`fs-extra mdi ${iconClass ? iconClass : `mdi-image`}`}></i>
      </span>
    )
  ) : (
    <img {...props} src={src} />
  );
};

const TokenSelectToggle = React.forwardRef(({ children, onClick, tradingPair, network }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div className="token-select-dropdown">
      <div className="icon">
        <h4 className="d-inline-block">
          {getSymbolsFromTradingPair(tradingPair).join("-").toUpperCase()}({network})
        </h4>
      </div>
    </div>
  </div>
));

const precisionArray = [0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001];

const PrecisionToggle = React.forwardRef(({ children, onClick, precision }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <div className="precision-select-dropdown">
      <div className="icon">
        <span>{precisionArray[precision - 1]}</span>
      </div>
    </div>
  </div>
));

function Exchange() {
  const history = useHistory();
  const { network, tradingPair } = useParams();
  const { isConnected, address } = useAccount();
  const { data: newsList } = useNewsList();

  const tradingPairChain = CHAIN_LIST.find((chain) => chain.urlName === network);

  const [targetTokenSymbol, baseTokenSymbol] = getSymbolsFromTradingPair(tradingPair);

  const [resolution, setResolution] = useState("all");
  const [orderType, setOrderType] = useState(ORDER_TYPE.LIMIT);
  const [orderSide, setOrderSide] = useState(ORDER_SIDE.BUY);
  const [fillPercentage, setFillPercentage] = useState(0);
  const [orderFilter, setOrderFiter] = useState(ORDER_FILTER.ALL);
  const [orderPrecision, setOrderPrecision] = useState(6);
  const [exchangePrice, setExchangePrice] = useState(0);
  const [exchangeAmount, setExchangeAmount] = useState(0);

  const [isPairBalanceFetching, setIsPairBalanceFetching] = useState(false);
  const [isTokenPairFetching, setIsTokenPairFetching] = useState(false);
  const [isTokenOrderFetching, setIsTokenOrderFetching] = useState(false);
  const [isTokenTradeFetching, setIsTokenTradeFetching] = useState(false);
  const [isTradingViewFetching, setIsTradingViewFetching] = useState(false);
  const [isPlaceOrdering, setIsPlaceOrdering] = useState(false);
  const [isRemoveOrdering, setIsRemoveOrdering] = useState(false);

  const [favoritePairs, setFavoritePairs] = useLocalStorage([]);

  const {
    isTokenPairListLoading,
    isTokenPairListSuccess,
    isTargetTokenListSuccess,
    tokenPairRefresh,
    authToken,
    currentUser,
    isAuthenticated,
  } = useContext(AuthContext);

  const exchangePriceRef = useRef(null);
  const exchangeAmountRef = useRef(null);

  const tokenPairList = useRecoilValue(atomTokenPairList);
  const [walletTokenBalance, walletBaseTokenBalance, walletUsdBalance] = useRecoilValue(atomWalletTradingPairBalance);
  const [buyOrders, sellOrders, allOrders, updatedTokenInfo] = useRecoilValue(atomTokenOrderList);
  const tokenTradeHistory = useRecoilValue(atomTokenTradeHistory);
  const tradingViewData = useRecoilValue(atomTradingViewData);
  const walletCaladexBalance = useRecoilValue(atomWalletCaladexBalance);
  const targetTokenList = useRecoilValue(atomTargetTokenList);

  const { refetch: pairBalanceRefetch } = useWalletTradingPairBalance(
    targetTokenSymbol,
    baseTokenSymbol,
    tradingPairChain.chainId
  );
  const { refetch: tokenOrderRefetch } = useTokenOrderList(
    tradingPairChain.chainId,
    targetTokenSymbol,
    baseTokenSymbol
  );
  const { refetch: tokenTradeRefetch } = useTokenTradeList(
    tradingPairChain.chainId,
    targetTokenSymbol,
    baseTokenSymbol
  );
  const { refetch: tradingViewRefetch } = useTradingViewData(
    tradingPairChain.chainId,
    targetTokenSymbol,
    baseTokenSymbol,
    resolution
  );
  const { isRefetching: isCaladexBalanceRefetching, refetch: caladexBalanceRefetch } = useWalletCaladexBalance(
    authToken,
    currentUser?.id
  );

  useEffect(() => {
    setExchangeAmount(0);
    setFillPercentage(0);
    setOrderType(ORDER_TYPE.LIMIT);
    setOrderSide(ORDER_SIDE.BUY);
    setOrderFiter(ORDER_FILTER.ALL);

    setIsTokenPairFetching(true);
    setIsTokenOrderFetching(true);
    setIsPairBalanceFetching(true);
    setIsTokenTradeFetching(true);
    setIsTradingViewFetching(true);

    tokenPairRefresh().then(() => {
      setIsTokenPairFetching(false);
      pairBalanceRefetch().then(() => {
        setIsPairBalanceFetching(false);
      });
      tokenOrderRefetch().then(() => {
        setIsTokenOrderFetching(false);
      });
      tokenTradeRefetch().then(() => {
        setIsTokenTradeFetching(false);
      });
      tradingViewRefetch().then(() => {
        setIsTradingViewFetching(false);
      });
    });
  }, [tradingPair]);

  // useEffect(() => {

  //   const fetchData = () => {
  //     setIsTokenOrderFetching(true);

  //     tokenPairRefresh().then(() => {
  //       tokenOrderRefetch().then(() => {
  //         setIsTokenOrderFetching(false);
  //         setFirstRender(false);
  //       });
  //     });
  //   };
  //   const interval = setInterval(fetchData, 60000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (isTokenPairListSuccess && isTargetTokenListSuccess) {
      caladexBalanceRefetch();
    }
  }, [isTokenPairListSuccess, isTargetTokenListSuccess]);

  useEffect(() => {
    if (!isEmpty(tokenPairList))
      setExchangePrice(
        formatDegits(
          orderSide === ORDER_SIDE.BUY
            ? +getTradingPairInfo(tokenPairList)?.market_buy_price
            : +getTradingPairInfo(tokenPairList)?.market_sell_price,
          // +getTradingPairInfo(tokenPairList)?.price,
          TOKEN_DIGITS
        )
      );
  }, [tokenPairList]);

  //   useEffect(() => {
  //     walletConnect(activate);
  //   }, []);

  useEffect(() => {
    setIsPairBalanceFetching(true);
    pairBalanceRefetch()
      .then(() => {
        setIsPairBalanceFetching(false);
      })
      .catch(() => {
        setIsPairBalanceFetching(false);
      });
  }, [isConnected, address]);

  useEffect(() => {
    tradingViewRefetch().then(() => {
      setIsTradingViewFetching(false);
    });
  }, [resolution]);

  const handleRemoveOrder = async (_id) => {
    let validationResult = "";
    await idValidationSchema.validate(_id).catch((err) => {
      validationResult = err.message;
      return false;
    });

    if (validationResult) {
      toast.error(validationResult);
      return false;
    }

    const _toast = toast.loading("Removing Order...");
    setIsRemoveOrdering(true);

    try {
      await Axios.post(
        `${BACKEND_API_URL}/order/delete/${_id}`,
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      toast.dismiss(_toast);
      toast.success("Order Successfully Removed!");
    } catch (e) {
      toast.dismiss(_toast);
      toast.error("Order Remove Failed!");
    }

    tokenOrderRefetch();
    caladexBalanceRefetch();
    setTimeout(() => {
      tradingViewRefetch();
      tokenPairRefresh();
    }, 2000);
    setIsRemoveOrdering(false);

    return false;
  };

  const handleOrderClick = (_price, _amount, _orderSide) => {
    setOrderType(ORDER_TYPE.MARKET);
    setOrderSide(_orderSide);
    setExchangePrice(+_price);
    if (exchangePriceRef.current) exchangePriceRef.current.focus();
    const _value = +_amount > getAvailableAmount() ? getAvailableAmount() : +_amount;

    setExchangeAmount(_value);
    exchangeAmountRef.current.focus();
    return false;
  };

  const handleFillPercentChange = (_percent) => {
    setFillPercentage(_percent);
    if (_percent) {
      const _value = (getAvailableAmount() * _percent) / 100;
      setExchangeAmount(_value.toFixed(2));
    }
    exchangeAmountRef.current.focus();
  };

  const handlePlaceOrder = async (_orderType, _orderSide, _exchangePrice, _exchangeAmount) => {
    if (isEmpty(currentUser) || !authToken) {
      toast.error("Please sign in to place bid.");
      return false;
    }

    const _final_exchangePrice = parseFloat(String(_exchangePrice).replace(/,/g, ""));

    if (_exchangeAmount <= EXCHANGE_LIMIT || (_orderType === ORDER_TYPE.LIMIT && _final_exchangePrice <= 0)) {
      toast.error("Please Enter Valid Token Price or Amount.");
      _orderType === ORDER_TYPE.LIMIT && _final_exchangePrice <= 0
        ? exchangePriceRef.current.focus()
        : exchangeAmountRef.current.focus();
      return false;
    }

    const _toast = toast.loading("Placing Order...");
    try {
      setIsPlaceOrdering(true);
      if (_orderType === ORDER_TYPE.LIMIT) {
        // check if price is valid or not
        if (
          (_orderSide === ORDER_SIDE.BUY &&
            _final_exchangePrice > +getTradingPairInfo(tokenPairList)?.market_sell_price) ||
          (_orderSide === ORDER_SIDE.SELL &&
            _final_exchangePrice < +getTradingPairInfo(tokenPairList)?.market_buy_price)
        ) {
          toast.dismiss(_toast);
          toast.error("Please Enter Valid Token Price for Limit Order.");
          setIsPlaceOrdering(false);
          exchangePriceRef.current.focus();
          return false;
        }

        await Axios.post(
          `${BACKEND_API_URL}/order/create/limit`,
          {
            user_id: currentUser?.id,
            chain_id: tradingPairChain?.chainId,
            token_id: getTradingPairInfo(tokenPairList)?.token_id,
            pair_token: baseTokenSymbol.toUpperCase(),
            order_class: _orderType,
            type: _orderSide,
            amount: _exchangeAmount,
            price: _final_exchangePrice,
          },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
      } else {
        await Axios.post(
          `${BACKEND_API_URL}/order/create/market`,
          {
            user_id: currentUser?.id,
            chain_id: tradingPairChain?.chainId,
            token_id: getTradingPairInfo(tokenPairList)?.token_id,
            pair_token: baseTokenSymbol.toUpperCase(),
            order_class: _orderType,
            type: _orderSide,
            amount: _exchangeAmount,
            price: _final_exchangePrice,
            partial_fillable: true,
          },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
      }

      setExchangeAmount(0);
      toast.dismiss(_toast);
      toast.success("Order Successfully placed!");
    } catch (e) {
      toast.dismiss(_toast);
      if (e?.response?.data?.message) {
        console.log("Exchange-Page@place-order:", e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        console.error("Exchange-Page@place-order:", e.message);
        toast.error(e.message);
      }
    }

    tokenOrderRefetch();
    tokenTradeRefetch();
    setTimeout(() => {
      tradingViewRefetch();
      tokenPairRefresh();
    }, 2000);
    caladexBalanceRefetch();
    setIsPlaceOrdering(false);
    return false;
  };

  const handleFavoriteToggle = (event, tokenPair) => {
    event.stopPropagation();
    event.preventDefault();
    const { chain_id, symbol, pair_token, volume24hr, price, percentChange } = tokenPair;
    const existingPair = (favoritePairs ?? []).find(
      (pair) => pair.chain_id === chain_id && pair.symbol === symbol && pair.pair_token === pair_token
    );
    if (existingPair) {
      setFavoritePairs(
        (favoritePairs ?? []).filter(
          (pair) => pair.chain_id !== chain_id || pair.symbol !== symbol || pair.pair_token !== pair_token
        )
      );
    } else {
      setFavoritePairs([...(favoritePairs ?? []), { chain_id, symbol, pair_token, volume24hr, price, percentChange }]);
    }

    return false;
  };

  const isFavorite = (tokenPair) => {
    const { chain_id, symbol, pair_token } = tokenPair;
    const existingPair = (favoritePairs ?? []).find(
      (pair) => pair.chain_id === chain_id && pair.symbol === symbol && pair.pair_token === pair_token
    );
    return !!existingPair;
  };

  const getTradingPairInfo = (_pairList) => {
    return _pairList.find(
      (_pair) =>
        _pair.chain_id === tradingPairChain.chainId &&
        _pair.symbol === targetTokenSymbol.toUpperCase() &&
        _pair.pair_token === baseTokenSymbol.toUpperCase()
    );
  };

  const getTokenBalance = (_symbol, _chainId, _balanceList) => {
    const _tokenBalance = _balanceList.find(
      (_balance) => _balance.token_id.chain_id === _chainId && _balance.token_id.symbol === _symbol.toUpperCase()
    );
    return (
      _tokenBalance ?? {
        caladex_balance: 0,
        order_balance: 0,
        stake_balance: 0,
      }
    );
  };

  const getAvailableAmount = () => {
    //const _tokenPrice = orderSide === ORDER_SIDE.BUY ? +getTradingPairInfo(tokenPairList)?.market_buy_price : +getTradingPairInfo(tokenPairList)?.market_sell_price

    const _tokenPrice = parseFloat(String(exchangePrice).replace(/,/g, ""));

    const _targetTokenBalance = getTokenBalance(
      targetTokenSymbol,
      tradingPairChain?.chainId,
      walletCaladexBalance
    )?.caladex_balance;

    const _baseTokenBalance = getTokenBalance(
      baseTokenSymbol,
      tradingPairChain?.chainId,
      walletCaladexBalance
    )?.caladex_balance;

    const _availableAmount =
      orderSide === ORDER_SIDE.BUY ? (+_tokenPrice > 0 ? +_baseTokenBalance / +_tokenPrice : 0) : +_targetTokenBalance;
    return _availableAmount;
  };

  const getPairTokenLogoUrl = (_targetTokenList, _baseSymbol, _chainId) => {
    const _pairToken = _targetTokenList.find(
      (_token) => _token.chain_id === _chainId && _token.symbol === _baseSymbol.toUpperCase()
    );

    return !!_pairToken ? _pairToken.logo_url : "";
  };

  const getTVL = () => {
    const _totalValueLocked = allOrders.reduce((prev, curr) => prev + curr.remain_amount, 0);

    return _totalValueLocked;
  };

  const handleResolution = (mode) => {
    setResolution(mode);
  };

  return (
    <>
      <Header2 activePage={`exchange`} />
      <div className="content-body" id="dashboard">
        <div className="container-fluid px-md-5">
          <div className="row">
            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
              <div className="card">
                <div className="card-header">
                  <Dropdown className="token-select-dropdown col-sm-12 col-md-8">
                    <Dropdown.Toggle
                      as={TokenSelectToggle}
                      tradingPair={tradingPair}
                      network={tradingPairChain?.chainName}
                    />
                    {!isEmpty(tokenPairList) ? (
                      <Dropdown.Menu size="sm" title="">
                        <PerfectScrollbar style={{ maxHeight: "500px" }}>
                          {/* {tokenPairList.map((_tokenPair) => (
                            <Link
                              key={uniqueId()}
                              to={`/exchange/${CHAIN_LIST.find((chain) => chain.chainId === _tokenPair.chain_id)?.urlName
                                }/${getTickerName(_tokenPair.symbol, _tokenPair.pair_token)}`}
                              className="dropdown-item "
                            >
                              <h4 className="d-inline-block">{`${_tokenPair.symbol} / ${_tokenPair.pair_token}(${CHAIN_LIST?.find((chain) => chain.chainId === _tokenPair.chain_id)?.chainName
                                })`}</h4>
                            </Link>
                          ))} */}
                          <Tab.Container defaultActiveKey="0x1">
                            <div className="card">
                              <div className="card-header justify-content-center">
                                <Nav variant="pills flex-column flex-md-row">
                                  <Nav.Link eventKey="STAR">
                                    <i className="mdi mdi-star"></i>
                                  </Nav.Link>
                                  {CHAIN_LIST.map((chain) => (
                                    <Nav.Link eventKey={chain.chainId}>{chain.chainName}</Nav.Link>
                                  ))}
                                </Nav>
                              </div>
                              <div className="card-body  price-pair">
                                <Tab.Content>
                                  <Tab.Pane eventKey="STAR">
                                    <table className="table table-hover exchange-pair-table">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>Pairs</th>
                                          <th>Last Price</th>
                                          <th>Change</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {!isTokenPairListLoading && isTokenPairListSuccess ? (
                                          (favoritePairs ?? []).map((pair, index) => (
                                            <tr
                                              key={`exchange-token-pair-pannel-${pair.chainId}-${index}`}
                                              onClick={() => {
                                                history.push(
                                                  `/exchange/${CHAIN_LIST.find(
                                                    (chain) => chain.chainId == pair.chain_id
                                                  )?.chainName.toLowerCase()}/${pair.symbol?.toLowerCase()}_${pair.pair_token?.toLowerCase()}`
                                                );
                                              }}
                                            >
                                              <td
                                                onClick={(e) => {
                                                  handleFavoriteToggle(e, pair);
                                                }}
                                              >
                                                <h4>
                                                  <i
                                                    className={`mdi mdi-star ${isFavorite(pair) ? "text-primary" : ""}`}
                                                  ></i>
                                                </h4>
                                              </td>
                                              <td className="text-white-50">
                                                <h4 className="mb-0">
                                                  {pair.symbol} / {pair.pair_token}
                                                </h4>
                                                Vol {formatDegits(+pair.volume24hr, SWALLOW_DEGITS)}
                                              </td>
                                              <td className="text-white-50">
                                                <h4>{formatDegits(pair.price, TOKEN_DIGITS)}</h4>
                                              </td>
                                              <td className={+pair.percentChange > 0 ? `text-success` : `text-danger`}>
                                                {formatDegits(+pair.percentChange, SWALLOW_DEGITS)}%
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr></tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </Tab.Pane>
                                  {CHAIN_LIST.map((chain) => (
                                    <Tab.Pane eventKey={chain.chainId}>
                                      <table className="table table-hover exchange-pair-table">
                                        <thead>
                                          <tr className="border-bottom">
                                            <th></th>
                                            <th>Pair/Vol</th>
                                            <th>Price</th>
                                            <th>Change</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {!isTokenPairListLoading && isTokenPairListSuccess ? (
                                            tokenPairList
                                              .filter((pair) => pair.chain_id === chain.chainId)
                                              .map((pair, index) => (
                                                <tr
                                                  key={`exchange-token-pair-pannel-${chain.chainId}-${index}`}
                                                  onClick={() => {
                                                    history.push(
                                                      `/exchange/${chain.chainName.toLowerCase()}/${pair.symbol?.toLowerCase()}_${pair.pair_token?.toLowerCase()}`
                                                    );
                                                  }}
                                                >
                                                  <td
                                                    onClick={(e) => {
                                                      handleFavoriteToggle(e, pair);
                                                    }}
                                                  >
                                                    <h6>
                                                      <i
                                                        className={`mdi mdi-star ${
                                                          isFavorite(pair) ? "text-primary" : ""
                                                        }`}
                                                      ></i>
                                                    </h6>
                                                  </td>
                                                  <td
                                                    className={`text-white-50 ${
                                                      window.innerWidth < 576 ? "responsive-font" : ""
                                                    }`}
                                                  >
                                                    <h6
                                                      className="mb-0"
                                                      style={{
                                                        fontSize: window.innerWidth < 576 && "0.6rem",
                                                      }}
                                                    >
                                                      {pair.symbol} / {pair.pair_token}
                                                    </h6>
                                                    Vol {formatDegits(+pair.volume24hr, SWALLOW_DEGITS)}
                                                  </td>
                                                  <td>
                                                    <h6 className="text-white-50">
                                                      {formatDegits(pair.price, TOKEN_DIGITS)}
                                                    </h6>
                                                  </td>
                                                  <td>
                                                    <h6
                                                      className={
                                                        +pair.percentChange > 0 ? `text-success` : `text-danger`
                                                      }
                                                    >
                                                      {formatDegits(+pair.percentChange, SWALLOW_DEGITS)}%
                                                    </h6>
                                                  </td>
                                                </tr>
                                              ))
                                          ) : (
                                            <tr></tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </Tab.Pane>
                                  ))}
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        </PerfectScrollbar>
                      </Dropdown.Menu>
                    ) : (
                      <Dropdown.Menu size="sm" title="" style={{ minWidth: "18rem" }}>
                        <CircularProgress sx={{ marginX: "auto", display: "block" }} />
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                  <span>
                    24h Change:{` `}
                    <strong
                      className={+getTradingPairInfo(tokenPairList)?.percentChange > 0 ? `text-success` : `text-danger`}
                    >
                      {` `}
                      {!isTokenPairFetching && isTokenPairListSuccess ? (
                        formatDegits(+getTradingPairInfo(tokenPairList)?.percentChange, SWALLOW_DEGITS) ?? "-"
                      ) : (
                        <CircularProgress
                          color={"primary"}
                          sx={{
                            width: "1rem !important",
                            height: "1rem !important",
                          }}
                        />
                      )}
                      %
                    </strong>
                  </span>
                </div>
                <div className="card-body">
                  <div className="row  justify-content-between">
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">Mark Price</p>
                      <h6>
                        {!isTokenPairFetching && isTokenPairListSuccess ? (
                          formatDegits(+getTradingPairInfo(tokenPairList)?.price, TOKEN_DIGITS) ?? "-"
                        ) : (
                          <CircularProgress
                            color={"primary"}
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}
                      </h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">24h High</p>
                      <h6>
                        {!isTokenPairFetching && isTokenPairListSuccess ? (
                          formatDegits(+getTradingPairInfo(tokenPairList)?.high24hr, TOKEN_DIGITS) ?? "-"
                        ) : (
                          <CircularProgress
                            color={"primary"}
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}
                      </h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">24h Low</p>
                      <h6>
                        {!isTokenPairFetching && isTokenPairListSuccess ? (
                          formatDegits(+getTradingPairInfo(tokenPairList)?.low24hr, TOKEN_DIGITS) ?? "-"
                        ) : (
                          <CircularProgress
                            color={"primary"}
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}
                      </h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">24h Volume</p>
                      <h6>
                        {!isTokenPairFetching && isTokenPairListSuccess ? (
                          formatDegits(+getTradingPairInfo(tokenPairList)?.volume24hr, SWALLOW_DEGITS) ?? "-"
                        ) : (
                          <CircularProgress
                            color={"primary"}
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}
                      </h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">TVL</p>
                      <h6>
                        {!isUndefined(allOrders) ? (
                          formatDegits(+getTVL(), SWALLOW_DEGITS) ?? "-"
                        ) : (
                          <CircularProgress
                            color={"primary"}
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Your Wallet: {isConnected ? humanReadableAccount(address) : ` -- `}</h4>
                  <span>
                    Updated <span>1</span> minutes ago
                  </span>
                </div>
                {/* {isPairBalanceRefetching && <LinearProgress />} */}
                <div className="card-body">
                  <div className="row justify-content-between">
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">
                        {!isTokenPairFetching && isTokenPairListSuccess ? (
                          getTradingPairInfo(tokenPairList)?.name ?? ` - `
                        ) : (
                          <CircularProgress
                            color={"primary"}
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}{" "}
                        Balance
                      </p>
                      <h6>
                        {isConnected ? (
                          !isPairBalanceFetching && !isUndefined(walletTokenBalance) ? (
                            formatDegits(walletTokenBalance, SWALLOW_DEGITS)
                          ) : (
                            <CircularProgress
                              sx={{
                                width: "1rem !important",
                                height: "1rem !important",
                              }}
                            />
                          )
                        ) : (
                          ` - `
                        )}
                      </h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">
                        {!isTokenPairFetching && isTokenPairListSuccess ? (
                          getTradingPairInfo(tokenPairList)?.pair_token ?? ` - `
                        ) : (
                          <CircularProgress
                            color={"primary"}
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}{" "}
                        Balance
                      </p>
                      <h6>
                        {isConnected ? (
                          !isPairBalanceFetching && !isUndefined(walletTokenBalance) ? (
                            formatDegits(walletBaseTokenBalance, TOKEN_DIGITS)
                          ) : (
                            <CircularProgress
                              sx={{
                                width: "1rem !important",
                                height: "1rem !important",
                              }}
                            />
                          )
                        ) : (
                          ` - `
                        )}
                      </h6>
                    </div>
                    <div className="col col-sm-auto col-6">
                      <p className="mb-2">USD Balance</p>
                      <h6>
                        {isConnected ? (
                          !isPairBalanceFetching && !isUndefined(walletTokenBalance) ? (
                            formatDegits(walletUsdBalance, TOKEN_DIGITS)
                          ) : (
                            <CircularProgress
                              sx={{
                                width: "1rem !important",
                                height: "1rem !important",
                              }}
                            />
                          )
                        ) : (
                          ` - `
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-12 col-md-12 col-xxl-8 mb-5">
              {/* {isTradingViewRefetching && <LinearProgress sx={{ marginBottom: "0.2rem" }} />} */}
              {/* <!-- TradingView Widget BEGIN --> */}
              <div id="timeline-chart" style={{ overflow: "auto" }}>
                {!isTradingViewFetching && !isUndefined(tradingViewData) ? (
                  <AreaChart data={tradingViewData} chartId={uniqueId()} handleResolution={handleResolution} />
                ) : (
                  <div style={{ height: "350px" }} className="d-flex justify-content-center align-items-center">
                    <CircularProgress />
                  </div>
                )}
              </div>
              {/* <Tradingview chainId={tradingPairChain?.chainId} targetTokenSymbol={targetTokenSymbol} baseTokenSymbol={baseTokenSymbol}/> */}
              {/* <!-- TradingView Widget END --> */}
            </div>

            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xxl-4 mb-5">
              <Tab.Container defaultActiveKey="ORDERBOOK">
                <div className="card">
                  <div className="card-header">
                    <Nav variant="pills">
                      <Nav.Link eventKey="ORDERBOOK">Order Book</Nav.Link>
                      <Nav.Link eventKey="TRADEHISTORY">Trade History</Nav.Link>
                    </Nav>
                  </div>
                  <PerfectScrollbar>
                    {/* {isTokenOrderRefetching && <LinearProgress />} */}
                    <div className="card-body order-book">
                      <Tab.Content>
                        <Tab.Pane eventKey="ORDERBOOK" style={{ height: "500px" }}>
                          <div className="row mb-3 pb-2 align-items-center border-bottom border-secondary">
                            <div className="order-icons col-md-8">
                              <Link
                                className="facebook text-center bg-success"
                                to={"#"}
                                onClick={() => {
                                  setOrderFiter(ORDER_FILTER.BUY);
                                }}
                              >
                                <span>
                                  <i className="fa fa-bars"></i>
                                </span>
                              </Link>
                              <Link
                                className="twitter text-center bg-danger"
                                to={"#"}
                                onClick={() => {
                                  setOrderFiter(ORDER_FILTER.SELL);
                                }}
                              >
                                <span>
                                  <i className="fa fa-bars"></i>
                                </span>
                              </Link>
                              <Link
                                className="googlePlus text-center both-order"
                                to={"#"}
                                onClick={() => {
                                  setOrderFiter(ORDER_FILTER.ALL);
                                }}
                              >
                                <span>
                                  <i className="fa fa-bars"></i>
                                </span>
                              </Link>
                            </div>
                            <div className="precison-select-dropdown col-md-4">
                              <Dropdown>
                                <Dropdown.Toggle as={PrecisionToggle} precision={orderPrecision} />
                                <Dropdown.Menu size="sm" title="">
                                  <Link
                                    to={"#"}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setOrderPrecision(1);
                                    }}
                                  >
                                    0.1
                                    {orderPrecision === 1 && <i className="fa fa-check float-end my-auto"></i>}
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setOrderPrecision(2);
                                    }}
                                  >
                                    0.01
                                    {orderPrecision === 2 && <i className="fa fa-check float-end my-auto"></i>}
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setOrderPrecision(3);
                                    }}
                                  >
                                    0.001
                                    {orderPrecision === 3 && <i className="fa fa-check float-end my-auto"></i>}
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setOrderPrecision(4);
                                    }}
                                  >
                                    0.0001
                                    {orderPrecision === 4 && <i className="fa fa-check float-end my-auto"></i>}
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setOrderPrecision(5);
                                    }}
                                  >
                                    0.00001
                                    {orderPrecision === 5 && <i className="fa fa-check float-end my-auto"></i>}
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="dropdown-item"
                                    onClick={() => {
                                      setOrderPrecision(6);
                                    }}
                                  >
                                    0.000001
                                    {orderPrecision === 6 && <i className="fa fa-check float-end my-auto"></i>}
                                  </Link>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <table
                            className={`table exchange-order-table table-hover ${
                              orderFilter !== ORDER_FILTER.ALL && orderFilter !== ORDER_FILTER.SELL ? `d-none` : ``
                            }`}
                          >
                            <thead>
                              <tr>
                                <th scope="col">Price</th>
                                <th scope="col" className="text-center">
                                  {!isTokenPairFetching && isTokenPairListSuccess ? (
                                    getTradingPairInfo(tokenPairList)?.symbol
                                  ) : (
                                    <CircularProgress
                                      color={"primary"}
                                      sx={{
                                        width: "1rem !important",
                                        height: "1rem !important",
                                      }}
                                    />
                                  )}{" "}
                                  Amount
                                </th>
                                <th scope="col" className="text-center">
                                  {!isTokenPairFetching && isTokenPairListSuccess ? (
                                    getTradingPairInfo(tokenPairList)?.pair_token
                                  ) : (
                                    <CircularProgress
                                      color={"primary"}
                                      sx={{
                                        width: "1rem !important",
                                        height: "1rem !important",
                                      }}
                                    />
                                  )}{" "}
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {(!isTokenOrderFetching || !firstRender) ? ( */}
                              {!isTokenOrderFetching ? (
                                !isUndefined(sellOrders) ? (
                                  sellOrders.map((_order, index) => (
                                    <tr
                                      key={`exchange-order-list-${index}`}
                                      onClick={() => {
                                        handleOrderClick(_order.price, _order.remain_amount, ORDER_SIDE.BUY);
                                      }}
                                    >
                                      <td>
                                        <h6 className="text-danger">{formatDegits(_order.price, orderPrecision)}</h6>
                                      </td>
                                      <td>
                                        <h6 className="text-center text-white-50">
                                          {formatDegits(_order.remain_amount, orderPrecision)}
                                          {/* {formatDegits(_order.remain_amount, SWALLOW_DEGITS)} */}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="text-center text-white-50">
                                          {formatDegits(_order.remain_amount * _order.price, orderPrecision)}
                                        </h6>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td>
                                      <h6 className="text-white-50"> - </h6>
                                    </td>
                                    <td>
                                      <h6 className="text-white-50"> - </h6>
                                    </td>
                                    <td>
                                      <h6 className="text-white-50"> - </h6>
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td className="text-danger" colSpan={3}>
                                    <div className="d-flex justify-content-center">
                                      <CircularProgress />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          <div className="order-book-divider">
                            <h6 className={+updatedTokenInfo?.day_change > 0 ? `text-success` : `text-danger`}>
                              {" "}
                              {!isTokenPairFetching && isTokenPairListSuccess && (
                                <>
                                  <i
                                    className={
                                      +updatedTokenInfo?.day_change > 0 ? `la la-arrow-up` : `la la-arrow-down`
                                    }
                                  ></i>{" "}
                                </>
                              )}
                              {!isTokenPairFetching && isTokenPairListSuccess ? (
                                formatDegits(+updatedTokenInfo?.price, TOKEN_DIGITS) ?? "-"
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}
                            </h6>
                            <span>
                              {!isTokenPairFetching && isTokenPairListSuccess ? (
                                `${formatDegits(
                                  // +getTradingPairInfo(tokenPairList)?.market_sell_price,
                                  +updatedTokenInfo?.market_sell_price,
                                  // TOKEN_DIGITS
                                  orderPrecision
                                )} / ${formatDegits(
                                  // +getTradingPairInfo(tokenPairList)?.market_buy_price,
                                  +updatedTokenInfo?.market_buy_price,
                                  orderPrecision
                                )}` ?? "-"
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}
                            </span>
                          </div>

                          <table
                            className={`table table-hover exchange-order-table ${
                              orderFilter !== ORDER_FILTER.ALL && orderFilter !== ORDER_FILTER.BUY ? `d-none` : ``
                            }`}
                          >
                            <thead>
                              <tr>
                                <th scope="col">Price</th>
                                <th scope="col" className="text-center">
                                  {!isTokenPairFetching && isTokenPairListSuccess ? (
                                    getTradingPairInfo(tokenPairList)?.symbol
                                  ) : (
                                    <CircularProgress
                                      color={"primary"}
                                      sx={{
                                        width: "1rem !important",
                                        height: "1rem !important",
                                      }}
                                    />
                                  )}{" "}
                                  Amount
                                </th>
                                <th scope="col" className="text-center">
                                  {!isTokenPairFetching && isTokenPairListSuccess ? (
                                    getTradingPairInfo(tokenPairList)?.pair_token
                                  ) : (
                                    <CircularProgress
                                      color={"primary"}
                                      sx={{
                                        width: "1rem !important",
                                        height: "1rem !important",
                                      }}
                                    />
                                  )}{" "}
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {(!isTokenOrderFetching || !firstRender) ? ( */}
                              {!isTokenOrderFetching ? (
                                !isUndefined(buyOrders) ? (
                                  buyOrders.map((_order, index) => (
                                    <tr
                                      key={`exchange-order-list-${index}`}
                                      onClick={() => {
                                        handleOrderClick(_order.price, _order.remain_amount, ORDER_SIDE.SELL);
                                      }}
                                    >
                                      <td>
                                        <h6 className="text-success">{formatDegits(_order.price, orderPrecision)}</h6>
                                      </td>
                                      <td>
                                        <h6 className="text-center text-white-50">
                                          {formatDegits(_order.remain_amount, orderPrecision)}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="text-center text-white-50">
                                          {formatDegits(_order.remain_amount * _order.price, orderPrecision)}
                                        </h6>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td>
                                      <h6 className="text-white-50"> - </h6>
                                    </td>
                                    <td>
                                      <h6 className="text-white-50"> - </h6>
                                    </td>
                                    <td>
                                      <h6 className="text-white-50"> - </h6>
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td className="text-success" colSpan={3}>
                                    <div className="d-flex justify-content-center">
                                      <CircularProgress />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="TRADEHISTORY">
                          <div className="table-responsive" style={{ height: "500px" }}>
                            <PerfectScrollbar>
                              <table className="table table-hover table-borderless">
                                <thead>
                                  <tr>
                                    <th scope="col">Price</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!isTokenTradeFetching && !isUndefined(tokenTradeHistory) ? (
                                    tokenTradeHistory.map((trade, index) => (
                                      <tr key={`exchange-trade-list-${index}`}>
                                        <td>
                                          <h6
                                            className={trade.type === ORDER_SIDE.BUY ? `text-success` : `text-danger`}
                                          >
                                            {formatDegits(trade.price, TOKEN_DIGITS)}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="text-white-50">
                                            {formatDegits(trade.amount, SWALLOW_DEGITS)}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className="text-white-50" width={`45%`}>
                                            {moment(trade.time).format("YYYY MM DD h:m:s")}
                                          </h6>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td className="text-success">
                                        <CircularProgress />
                                      </td>
                                      <td>
                                        <CircularProgress />
                                      </td>
                                      <td>
                                        <CircularProgress />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </PerfectScrollbar>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </PerfectScrollbar>
                </div>
              </Tab.Container>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xxl-6 mb-5">
              <Tab.Container defaultActiveKey="limit">
                <div className="card">
                  <div className="card-header flex-column">
                    <div className="btn-group btn-block mt-3 col-12">
                      <button
                        type="button"
                        className={`btn btn-lg ${orderSide === ORDER_SIDE.BUY && `btn-success`}`}
                        onClick={() => {
                          setOrderSide(ORDER_SIDE.BUY);
                          setExchangePrice(formatDegits(+getTradingPairInfo(tokenPairList)?.market_sell_price, 4));
                        }}
                      >
                        Buy
                      </button>
                      <button
                        type="button"
                        className={`btn btn-lg ${orderSide === ORDER_SIDE.SELL ? `btn-danger` : `btn-secondary`}`}
                        onClick={() => {
                          setOrderSide(ORDER_SIDE.SELL);
                          setExchangePrice(
                            formatDegits(+getTradingPairInfo(tokenPairList)?.market_buy_price, TOKEN_DIGITS)
                          );
                        }}
                      >
                        Sell
                      </button>
                    </div>
                    <Nav variant="pills mt-5">
                      <Nav.Link
                        onClick={() => {
                          setOrderType(ORDER_TYPE.LIMIT);
                        }}
                        className={orderType === ORDER_TYPE.LIMIT && `active`}
                      >
                        Limit
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => {
                          setOrderType(ORDER_TYPE.MARKET);
                        }}
                        className={orderType === ORDER_TYPE.MARKET && `active`}
                      >
                        Market
                      </Nav.Link>
                    </Nav>
                  </div>
                  <div className="card-body market-limit">
                    <div className="mb-3 row align-items-center">
                      {orderType === ORDER_TYPE.LIMIT ? (
                        <div className="col-md-12">
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <label className="input-group-text text-white  bg-primary">Price</label>
                            </div>
                            <input
                              type="text"
                              className="form-control text-end"
                              style={{ fontSize: "0.875rem" }}
                              ref={exchangePriceRef}
                              placeholder="0"
                              value={exchangePrice}
                              onChange={(e) => {
                                setExchangePrice(e.target.value);
                              }}
                              onKeyUp={(e) => {
                                if (e.key == "Enter") exchangeAmountRef.current.focus();
                              }}
                            />
                            <div className="input-group-prepend">
                              <label className="input-group-text text-white  bg-secondary">
                                {!isTokenPairFetching && isTokenPairListSuccess ? (
                                  getTradingPairInfo(tokenPairList)?.pair_token ?? "-"
                                ) : (
                                  <CircularProgress
                                    color={"primary"}
                                    sx={{
                                      width: "1rem !important",
                                      height: "1rem !important",
                                    }}
                                  />
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              style={{ fontSize: "0.875rem" }}
                              className="form-control text-end"
                              disabled
                              placeholder="Most Optimal Price"
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label className="input-group-text text-white  bg-secondary">Amount</label>
                          </div>
                          <input
                            type="text"
                            style={{ fontSize: "0.875rem" }}
                            className={`form-control text-end ${getAvailableAmount() < exchangeAmount && `is-invalid`}`}
                            ref={exchangeAmountRef}
                            placeholder="0"
                            value={exchangeAmount > 0 ? exchangeAmount : ""}
                            onChange={(e) => {
                              setExchangeAmount(e.target.value);
                            }}
                            onKeyUp={(e) => {
                              if (e.key == "Enter" && !isPlaceOrdering)
                                handlePlaceOrder(orderType, orderSide, exchangePrice, exchangeAmount);
                            }}
                          />
                          <div className="input-group-prepend">
                            <label className="input-group-text text-white  bg-secondary">
                              {!isTokenPairFetching && isTokenPairListSuccess ? (
                                getTradingPairInfo(tokenPairList)?.symbol ?? "-"
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between my-3">
                          <div>
                            <p>
                              <h6 className={`mb-1 ${orderSide === ORDER_SIDE.SELL ? `text-danger` : ``}`}>
                                Available{" "}
                                {!isTokenPairFetching && isTokenPairListSuccess ? (
                                  getTradingPairInfo(tokenPairList)?.symbol ?? "-"
                                ) : (
                                  <CircularProgress
                                    color={"primary"}
                                    sx={{
                                      width: "1rem !important",
                                      height: "1rem !important",
                                    }}
                                  />
                                )}
                              </h6>
                            </p>
                            <h6 className={orderSide === ORDER_SIDE.SELL ? `text-danger` : ``}>
                              {!isCaladexBalanceRefetching ? (
                                !isEmpty(walletCaladexBalance) ? (
                                  formatDegits(
                                    getTokenBalance(targetTokenSymbol, tradingPairChain?.chainId, walletCaladexBalance)
                                      ?.caladex_balance,
                                    SWALLOW_DEGITS
                                  )
                                ) : (
                                  "-"
                                )
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}{" "}
                              {!isTokenPairFetching && isTokenPairListSuccess ? (
                                getTradingPairInfo(tokenPairList)?.symbol ?? "-"
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}
                            </h6>
                          </div>
                          <div>
                            <p>
                              <h6 className={`mb-1 ${orderSide === ORDER_SIDE.BUY ? `text-success` : ``}`}>
                                Available{" "}
                                {!isTokenPairFetching && isTokenPairListSuccess ? (
                                  getTradingPairInfo(tokenPairList)?.pair_token ?? "-"
                                ) : (
                                  <CircularProgress
                                    color={"primary"}
                                    sx={{
                                      width: "1rem !important",
                                      height: "1rem !important",
                                    }}
                                  />
                                )}
                              </h6>
                            </p>
                            <h6 className={orderSide === ORDER_SIDE.BUY ? `text-success` : ``}>
                              {!isCaladexBalanceRefetching ? (
                                !isEmpty(walletCaladexBalance) ? (
                                  formatDegits(
                                    getTokenBalance(baseTokenSymbol, tradingPairChain?.chainId, walletCaladexBalance)
                                      ?.caladex_balance,
                                    TOKEN_DIGITS
                                  )
                                ) : (
                                  "-"
                                )
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}{" "}
                              {!isTokenPairFetching && isTokenPairListSuccess ? (
                                getTradingPairInfo(tokenPairList)?.pair_token ?? "-"
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-12 d-flex mb-3 justify-content-between"
                        key={`fill-percent-box-${orderSide}`}
                      >
                        <div className="d-flex flex-column justify-content-center text-center">
                          <span
                            className="rounded-circle text-primary display-6"
                            onClick={() => {
                              handleFillPercentChange(0);
                            }}
                          >
                            <i className={fillPercentage > 0 ? `la la-dot-circle-o` : `la la-circle`}></i>
                          </span>
                          <span className={fillPercentage > 0 ? `text-primary` : ``} style={{ fontSize: "0.875rem" }}>
                            0%
                          </span>
                        </div>
                        <div className="d-flex flex-column justify-content-center text-center">
                          <span
                            className="rounded-circle text-primary display-6"
                            onClick={() => {
                              handleFillPercentChange(25);
                            }}
                          >
                            <i className={fillPercentage >= 25 ? `la la-dot-circle-o` : `la la-circle`} title="25%"></i>
                          </span>
                          <span className={fillPercentage >= 25 ? `text-primary` : ``} style={{ fontSize: "0.875rem" }}>
                            25%
                          </span>
                        </div>
                        <div className="d-flex flex-column justify-content-center text-center">
                          <span
                            className="rounded-circle text-primary display-6"
                            onClick={() => {
                              handleFillPercentChange(50);
                            }}
                          >
                            <i className={fillPercentage >= 50 ? `la la-dot-circle-o` : `la la-circle`} title="50%"></i>
                          </span>
                          <span className={fillPercentage >= 50 ? `text-primary` : ``} style={{ fontSize: "0.875rem" }}>
                            50%
                          </span>
                        </div>
                        <div className="d-flex flex-column justify-content-center text-center">
                          <span
                            className="rounded-circle text-primary display-6"
                            onClick={() => {
                              handleFillPercentChange(75);
                            }}
                          >
                            <i className={fillPercentage >= 75 ? `la la-dot-circle-o` : `la la-circle`} title="75%"></i>
                          </span>
                          <span className={fillPercentage >= 75 ? `text-primary` : ``} style={{ fontSize: "0.875rem" }}>
                            75%
                          </span>
                        </div>
                        <div className="d-flex flex-column justify-content-center text-center">
                          <span
                            className="rounded-circle text-primary display-6"
                            onClick={() => {
                              handleFillPercentChange(100);
                            }}
                          >
                            <i
                              className={fillPercentage == 100 ? `la la-dot-circle-o` : `la la-circle`}
                              title="100%"
                            ></i>
                          </span>
                          <span
                            className={fillPercentage == 100 ? `text-primary` : ``}
                            style={{ fontSize: "0.875rem" }}
                          >
                            100%
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label className="input-group-text text-white  bg-secondary">Total</label>
                          </div>
                          <input
                            type="text"
                            className="form-control text-end"
                            style={{ fontSize: "0.875rem" }}
                            placeholder="0"
                            value={formatDegits(
                              parseFloat(exchangeAmount) * parseFloat(String(exchangePrice).replace(/,/g, "")),
                              TOKEN_DIGITS
                            )}
                            onChange={() => {}}
                          />
                          <div className="input-group-prepend">
                            <label className="input-group-text text-white  bg-secondary">
                              {!isTokenPairFetching && isTokenPairListSuccess ? (
                                getTradingPairInfo(tokenPairList)?.pair_token
                              ) : (
                                <CircularProgress
                                  color={"primary"}
                                  sx={{
                                    width: "1rem !important",
                                    height: "1rem !important",
                                  }}
                                />
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                      {isAuthenticated ? (
                        !isPlaceOrdering ? (
                          <button
                            type="button col-md-12"
                            className={`btn btn-lg ${orderSide === ORDER_SIDE.BUY ? `btn-success` : `btn-danger`}`}
                            onClick={() => {
                              handlePlaceOrder(orderType, orderSide, exchangePrice, exchangeAmount);
                            }}
                            disabled={getAvailableAmount() < exchangeAmount || isPlaceOrdering}
                          >
                            {orderSide === ORDER_SIDE.BUY ? `Buy` : `Sell`}
                          </button>
                        ) : (
                          <div className="col-md-12 d-flex justify-content-center">
                            <CircularProgress />
                          </div>
                        )
                      ) : (
                        <Link className="btn btn-lg col-md-12 btn-info col-md-12" to={`/signin`}>
                          Log In
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
            <div className="col-xl-3 col-lg-12 col-md-12 col-xxl-6">
              <Tab.Container defaultActiveKey="ETH">
                <div className="card">
                  <div className="card-header">
                    <Nav variant="pills">
                      <Nav.Link eventKey="ETH">ETH</Nav.Link>
                      <Nav.Link eventKey="POS">PoS</Nav.Link>
                      <Nav.Link eventKey="BSC">BSC</Nav.Link>
                    </Nav>
                  </div>
                  <PerfectScrollbar>
                    <div className="card-body  price-pair">
                      <Tab.Content>
                        <Tab.Pane eventKey="STAR">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Pairs</th>
                                <th>Last Price</th>
                                <th>Change</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr>
                                                                <td>
                                                                    <i className="mdi mdi-star"></i>{" "}
                                                                    ETH/BTC
                                                                </td>
                                                                <td>
                                                                    0.00020255
                                                                </td>
                                                                <td className="red">
                                                                    -2.58%
                                                                </td>
                                                            </tr> */}
                            </tbody>
                          </table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ETH">
                          <table className="table table-hover exchange-pair-table">
                            <thead>
                              <tr>
                                <th>Pairs</th>
                                <th>Last Price</th>
                                <th>Change</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isTokenPairListLoading && isTokenPairListSuccess ? (
                                tokenPairList
                                  .filter((pair) => pair.chain_id === "0x1")
                                  .map((pair, index) => (
                                    <tr
                                      key={`exchange-token-pair-pannel-2-${index}`}
                                      onClick={() => {
                                        history.push(
                                          `/exchange/ethereum/${pair.symbol?.toLowerCase()}_${pair.pair_token?.toLowerCase()}`
                                        );
                                      }}
                                    >
                                      <td>
                                        <h6 className="text-white-50">
                                          <i className="mdi mdi-star"></i> {pair.symbol} / {pair.pair_token}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="text-white-50">{formatDegits(pair.price, TOKEN_DIGITS)}</h6>
                                      </td>
                                      <td>
                                        <h6 className={+pair.percentChange > 0 ? `text-success` : `text-danger`}>
                                          {formatDegits(+pair.percentChange, SWALLOW_DEGITS)}%
                                        </h6>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr></tr>
                              )}
                            </tbody>
                          </table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="POS">
                          <table className="table table-hover exchange-pair-table">
                            <thead>
                              <tr>
                                <th>Pairs</th>
                                <th>Last Price</th>
                                <th>Change</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isTokenPairListLoading && isTokenPairListSuccess ? (
                                tokenPairList
                                  .filter((pair) => pair.chain_id === "0x89")
                                  .map((pair, index) => (
                                    <tr
                                      key={`exchange-token-pair-pannel-2-${index}`}
                                      onClick={() => {
                                        history.push(
                                          `/exchange/polygon/${pair.symbol?.toLowerCase()}_${pair.pair_token?.toLowerCase()}`
                                        );
                                      }}
                                    >
                                      <td>
                                        <h6 className="text-white-50">
                                          <i className="mdi mdi-star"></i> {pair.symbol} / {pair.pair_token}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="text-white-50">{formatDegits(pair.price, TOKEN_DIGITS)}</h6>
                                      </td>
                                      <td>
                                        <h6 className={+pair.percentChange > 0 ? `text-success` : `text-danger`}>
                                          {formatDegits(+pair.percentChange, SWALLOW_DEGITS)}%
                                        </h6>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr></tr>
                              )}
                            </tbody>
                          </table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="BSC">
                          <table className="table table-hover exchange-pair-table">
                            <thead>
                              <tr>
                                <th>Pairs</th>
                                <th>Last Price</th>
                                <th>Change</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isTokenPairListLoading && isTokenPairListSuccess ? (
                                tokenPairList
                                  .filter((pair) => pair.chain_id === "0x38")
                                  .map((pair, index) => (
                                    <tr
                                      key={`exchange-token-pair-pannel-2-${index}`}
                                      onClick={() => {
                                        history.push(
                                          `/exchange/binance/${pair.symbol?.toLowerCase()}_${pair.pair_token?.toLowerCase()}`
                                        );
                                      }}
                                    >
                                      <td>
                                        <h6 className="text-white-50">
                                          <i className="mdi mdi-star"></i> {pair.symbol} / {pair.pair_token}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="text-white-50">{formatDegits(pair.price, TOKEN_DIGITS)}</h6>
                                      </td>
                                      <td>
                                        <h6 className={+pair.percentChange > 0 ? `text-success` : `text-danger`}>
                                          {formatDegits(+pair.percentChange, SWALLOW_DEGITS)}%
                                        </h6>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr></tr>
                              )}
                            </tbody>
                          </table>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </PerfectScrollbar>
                </div>
              </Tab.Container>
            </div>
            <div className="col-xl-7 col-lg-12 col-xxl-12">
              <Tab.Container defaultActiveKey="ACTIVE-ORDERS">
                <div className="card">
                  <div className="card-header">
                    <Nav variant="pills">
                      <Nav.Link eventKey="ACTIVE-ORDERS">Active Orders</Nav.Link>
                      <Nav.Link eventKey="TRADE-HISTORY">Trade History</Nav.Link>
                    </Nav>
                  </div>
                  <PerfectScrollbar>
                    <div className="card-body open-position-table">
                      <div className="market-history market-order">
                        <Tab.Content>
                          <Tab.Pane eventKey="ACTIVE-ORDERS">
                            <div className="table-responsive">
                              <table className="table table-striped exchange-active-order-table" id="tbUser">
                                <thead>
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Symbol</th>
                                    <th scope="col">Pair Token</th>
                                    <th scope="col">Exchange Type</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* {(!isTokenOrderFetching || !firstRender) ? ( */}
                                  {!isTokenOrderFetching ? (
                                    !isUndefined(allOrders) && isAuthenticated ? (
                                      allOrders
                                        .filter((_order) => !_order.is_traded && currentUser?.id === _order.user_id)
                                        .map((_order, index) => (
                                          <tr key={`exchange-trade-history-${index}`}>
                                            <td>
                                              <h6 className="text-white font-bold">
                                                {moment(_order.time).format("YYYY MM DD h:m:s")}
                                              </h6>
                                            </td>
                                            <th scope="row">
                                              <ImageContainer
                                                src={`${BACKEND_API_URL}/files${getPairTokenLogoUrl(
                                                  targetTokenList,
                                                  _order.pair_token,
                                                  _order.chain_id
                                                )}`}
                                                width={`20px`}
                                              />
                                            </th>
                                            <td>
                                              <h6 className="text-white-50">{_order.pair_token}</h6>
                                            </td>
                                            <td>
                                              <h6 className={_order.type === "buy" ? `text-success` : `text-danger`}>
                                                {_order.type}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className="text-white-50">
                                                {formatDegits(_order.price, TOKEN_DIGITS)}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className="text-white-50">
                                                {formatDegits(_order.remain_amount, SWALLOW_DEGITS)}
                                              </h6>
                                            </td>
                                            {/* <td>
                                              <h6 className="text-white-50">{formatDegits(_order.amount, SWALLOW_DEGITS)}</h6>
                                            </td> */}
                                            <td>
                                              <h6 className="text-white-50">
                                                {formatDegits(_order.price * _order.amount, SWALLOW_DEGITS)}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className="text-white-50">
                                                <i
                                                  className="la la-lg la-trash text-danger"
                                                  onClick={() => {
                                                    handleRemoveOrder(_order._id);
                                                  }}
                                                ></i>
                                              </h6>
                                            </td>
                                          </tr>
                                        ))
                                    ) : (
                                      <tr></tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={8}>
                                        <CircularProgress
                                          sx={{
                                            marginX: "auto",
                                            display: "block",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="TRADE-HISTORY">
                            <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Symbol</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Filled</th>
                                    <th scope="col">Remaining</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!isTokenOrderFetching ? (
                                    !isUndefined(allOrders) && isAuthenticated ? (
                                      allOrders
                                        .filter((_order) => _order.is_traded && currentUser?.id === _order.user_id)
                                        .map((_order, index) => (
                                          <tr key={`exchange-order-history-${index}`}>
                                            <td>
                                              <h6 className="text-white font-bold">
                                                {moment(_order.time).format("YYYY MM DD h:m:s")}
                                              </h6>
                                            </td>
                                            <th scope="row">
                                              <ImageContainer
                                                src={`${BACKEND_API_URL}/files${getPairTokenLogoUrl(
                                                  targetTokenList,
                                                  _order.pair_token,
                                                  _order.chain_id
                                                )}`}
                                                width={`20px`}
                                              />
                                            </th>
                                            <td>
                                              <h6 className={_order.type === "buy" ? `text-success` : `text-danger`}>
                                                {_order.type}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className="text-white-50">
                                                {formatDegits(_order.amount, SWALLOW_DEGITS)}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className="text-white-50">
                                                {formatDegits(_order.amount - _order.remain_amount, SWALLOW_DEGITS)}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className="text-white-50">
                                                {formatDegits(_order.remain_amount, SWALLOW_DEGITS)}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className="text-white-50">
                                                {formatDegits(_order.price, TOKEN_DIGITS)}
                                              </h6>
                                            </td>
                                            <td>
                                              <h6 className={_order.is_traded ? "text-success" : "text-danger"}>
                                                {_order.is_traded ? (
                                                  <i className="la la-lg la-check text-primary cursor-pointer"></i>
                                                ) : (
                                                  <i className="la la-lg la-clock text-warning cursor-pointer"></i>
                                                )}
                                              </h6>
                                            </td>
                                          </tr>
                                        ))
                                    ) : (
                                      <tr></tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={8}>
                                        <CircularProgress
                                          sx={{
                                            marginX: "auto",
                                            display: "block",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </PerfectScrollbar>
                </div>
              </Tab.Container>
            </div>
            <div className="col-xl-2 col-lg-12 col-md-12 col-xxl-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Market News</h4>
                </div>
                <PerfectScrollbar>
                  <div className="card-body market-news">
                    <div className="row">
                      {!isUndefined(newsList) &&
                        newsList.map((value, index) => (
                          <div className="col-xl-12 mx-auto" key={`news-list-${index}`}>
                            <ImageContainer
                              className="img-fluid mx-auto"
                              src={`${BACKEND_FILE_URL}/${value.header_img}`}
                              alt="..."
                              height="100"
                            />
                            <Link to={`/blog/${value._id}`} className="news-content">
                              <h6 className="mt-2 mb-3 text-center">{value.title}</h6>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default Exchange;
