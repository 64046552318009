import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
import { CircularProgress } from "@mui/material";
import React from "react";
import { getRefreshToken } from "../../shared/helpers";

export const ProtectedRoute = ({ component, path }) => {
  const { currentUser, isAuthenticated, authToken } = useContext(AuthContext);

  const refreshToken = getRefreshToken();
  console.log({ isAuthenticated, currentUser, authToken, refreshToken });
  return isAuthenticated && currentUser && (authToken || refreshToken) ? (
    <Route exact path={path} component={component} />
  ) : refreshToken ? (
    <div className="d-flex justify-content-center align-items-center w-100" style={{ minHeight: "80vh" }}>
      <CircularProgress />
    </div>
  ) : (
    <Redirect to="/" />
  );
};
